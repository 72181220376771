<template>
	<div class="main-contents">
		<!-- 로그인 폼 -->
		<div class="contents">
			<div class="contents_body">
				<div class="tit">
					<span>401 Unauthorized!!</span><br/>
					로그인 후 이용해 주시기 바랍니다.
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
		};
	},
	methods: {
	},
	computed: {
	}
};
</script>